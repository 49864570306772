<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow">
      <div class="column q-gutter-lg">
        <q-expansion-item v-model="expanded" class="bg-grey-4">
          <template v-slot:header>
            <q-item-section>
              <span class="text-bold text-body1">
                {{ data.visitDetail.nama_customer }}
              </span>
              <span>
                {{ moment(data.visitDetail.date).format("DD MMM YYYY") }}
              </span>
            </q-item-section>

            <q-item-section side>
              <div class="row items-center q-gutter-xs" v-if="!expanded">
                <q-btn
                  round
                  flat
                  dense
                  color="primary"
                  icon="photo_camera"
                  @click.stop.prevent="showFoto = true"
                  :disable="
                    !data.visitDetail.photo || data.visitDetail.photo == ''
                  "
                >
                  <q-tooltip>
                    Show Photo
                  </q-tooltip>
                </q-btn>

                <q-btn
                  round
                  flat
                  dense
                  color="primary"
                  icon="place"
                  type="a"
                  :href="
                    `https://www.google.co.id/maps/@${data.visitDetail.coordinate},20z`
                  "
                  target="_blank"
                  :disable="
                    !data.visitDetail.coordinate ||
                      data.visitDetail.coordinate == ''
                  "
                >
                  <q-tooltip>
                    Show Location
                  </q-tooltip>
                </q-btn>
                <q-btn
                  round
                  flat
                  dense
                  color="primary"
                  icon="lock_open"
                  v-if="data.visitDetail.is_summary_unlocked == '0'"
                  @click.stop.prevent="
                    unlockVisit(route.params.id);
                    getDetail(route.params.id);
                  "
                >
                  <q-tooltip>
                    Unlock Summary
                  </q-tooltip>
                </q-btn>
                <q-btn
                  round
                  flat
                  dense
                  color="primary"
                  icon="lock"
                  v-if="
                    data.visitDetail.is_summary_unlocked == '1' &&
                      data.visitDetail.is_check_in == '1'
                  "
                  @click.stop.prevent="
                    lockVisit(route.params.id);
                    getDetail(route.params.id);
                  "
                >
                  <q-tooltip>
                    Unlock Summary
                  </q-tooltip>
                </q-btn>
              </div>
            </q-item-section>
          </template>
          <q-item class="bg-grey-3 text-black">
            <q-item-section>
              <q-markup-table
                dense
                class="bg-transparent text-caption q-my-md"
                flat
                wrap-cells
              >
                <tr v-if="data.visitDetail.check_in_time">
                  <td>Check-In</td>
                  <td>:</td>
                  <td>
                    {{
                      moment(data.visitDetail.check_in_time).format(
                        "DD MMM YYYY HH:mm:ss"
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Nama Sales</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.nama_user }}</td>
                </tr>
                <tr>
                  <td>Costumer Type</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.costumer_type }}</td>
                </tr>
                <tr>
                  <td>Kota</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.city }}</td>
                </tr>
                <tr>
                  <td>Application</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.application }}</td>
                </tr>
                <tr>
                  <td>Visit Type</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.type }}</td>
                </tr>
              </q-markup-table>

              <div class="row justify-around">
                <q-btn
                  flat
                  color="primary"
                  label="Show Photo"
                  icon="photo_camera"
                  @click="showFoto = true"
                  :disable="
                    !data.visitDetail.photo || data.visitDetail.photo == ''
                  "
                ></q-btn>

                <q-btn
                  flat
                  color="primary"
                  label="Show Location"
                  icon="place"
                  type="a"
                  :href="
                    `https://www.google.co.id/maps/@${data.visitDetail.coordinate},20z`
                  "
                  target="_blank"
                  :disable="
                    !data.visitDetail.coordinate ||
                      data.visitDetail.coordinate == ''
                  "
                ></q-btn>

                <q-btn
                  flat
                  color="primary"
                  label="Unlock"
                  icon="lock_open"
                  v-if="data.visitDetail.is_summary_unlocked == '0'"
                  @click="unlockVisit(data.visitDetail)"
                ></q-btn>
                <q-btn
                  flat
                  color="primary"
                  label="Lock"
                  icon="lock"
                  v-if="
                    data.visitDetail.is_summary_unlocked == '1' &&
                      data.visitDetail.is_check_in == '1'
                  "
                  @click="lockVisit(data.visitDetail)"
                ></q-btn>
              </div>
            </q-item-section>
          </q-item>
        </q-expansion-item>
        <div class="q-pa-md">
          <q-input
            outlined
            readonly
            class="q-mb-md"
            type="textarea"
            placeholder="Visit Summary"
            v-model="data.visitDetail.summary"
          ></q-input>
          <div class="column q-gutter-sm">
            <div class="row justify-evenly">
              <q-radio
                v-model="data.visitDetail.is_valid"
                val="1"
                label="Valid"
              />
              <q-radio
                v-model="data.visitDetail.is_valid"
                val="0"
                label="Invalid"
              />
              <!-- <q-checkbox
            label="Valid"
            true-value="1"
            false-value="0"
            v-model="data.visitDetail.is_valid"
          ></q-checkbox> -->
            </div>
            <q-input
              outlined
              v-if="data.visitDetail.is_valid == 0"
              type="textarea"
              placeholder="Invalid Note"
              v-model="data.visitDetail.invalid_note"
            ></q-input>
          </div>
        </div>
      </div>
    </q-scroll-area>

    <q-btn
      class="no-border-radius"
      color="primary"
      label="check"
      @click="checkAdmin(data.visitDetail)"
    ></q-btn>

    <q-dialog v-model="showFoto">
      <q-card style="min-width:300px">
        <q-card-section>
          <q-img
            :src="`../bukti_visit/${data.visitDetail.photo}`"
            spinner-color="white"
          ></q-img>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn unelevated color="primary" label="Close" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { onMounted } from "vue";
import useVisit from "./useVisit";
import { useQuasar } from "quasar";
export default {
  setup() {
    const {
      getDetail,
      data,
      route,
      router,
      unlockVisit,
      checkAdmin,
      lockVisit,
    } = useVisit();

    const $q = useQuasar();

    let expanded = ref(false);

    let showFoto = ref(false);

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getDetail(route.params.id);
        data.visitDetail.is_valid = "1";
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      getDetail,
      checkAdmin,
      lockVisit,
      unlockVisit,
      showFoto,
      data,
      route,
      router,
      moment,
      expanded,
      $q,
    };
  },
};
</script>
